import React, { ReactElement } from 'react';
import { observer } from 'mobx-react-lite';
import { Helmet } from 'react-helmet';

import Popular from '@components/Popular';
import Back from '@components/Back';
import ScrollUp from '@components/ScrollUp';
import { useRootStore } from '@stores/index';
import { SITE_TITLE } from '@constants/meta';
import { ArticleWrapper } from '@rambler-help/components';

import { styled } from '@linaria/react';
import {
  fontMontserrat,
  fontColorDefault,
  WrapperStyles,
  RightColumnStyles
} from '@rambler-help/shared';

const ProjectPageStyled = styled.div`
  ${WrapperStyles}
  h1 {
    margin: 30px 0 20px;
    ${fontMontserrat}
    ${fontColorDefault}
    font-size: 26px;
    line-height: 30px;
    font-weight: 800;
  }
  h2 {
    margin: 30px 0;
    ${fontMontserrat}
    ${fontColorDefault}
    font-size: 38px;
    line-height: 45px;
    font-weight: 800;
  }
`;
const Content = styled.div`
  ${RightColumnStyles}
`;
const Description = styled.div`
  margin-bottom: 20px;
`;

const Project = observer((): ReactElement => {
  const { projects } = useRootStore();
  return (
    <React.Fragment>
      <Helmet>
        <title>{`${projects?.project?.title} — ${SITE_TITLE}`}</title>
      </Helmet>
      <ProjectPageStyled>
        <Content>
          <Back to={'/'}>Все сервисы Рамблера</Back>
          {projects?.project?.id === 69 ? (
            <h2>{projects?.project?.title}</h2>
          ) : (
            <>
              <h2>Помощь по проекту «{projects?.project?.title}»</h2>
              <Popular />
              <h1>О проекте «{projects?.project?.title}»</h1>
            </>
          )}
          <Description>
            <ArticleWrapper>
              {projects?.project?.description && (
                <div dangerouslySetInnerHTML={{__html: projects?.project?.description}} />
              )}
            </ArticleWrapper>
          </Description>
          <ScrollUp />
        </Content>
      </ProjectPageStyled>
    </React.Fragment>
  );
});

export default Project;
