import React, { ReactElement } from 'react';

import { useRootStore } from '@stores/index';
import { RouteParameters, RouteNames } from '@rambler-help/shared';

import { styled } from '@linaria/react';
import { fontDefault, styleLink } from '@rambler-help/shared';

const Popular = styled.div`
  ${fontDefault}
  ${styleLink}
`;
const Title = styled.div`
  margin-bottom: 20px;
  font-weight: 600;
`;
const Questions = styled.div``;
const Question = styled.div`
  margin-bottom: 20px;
  line-height: 20px;
`;

const PopularComponent = (): ReactElement | null => {
  const { projects, articles } = useRootStore();
  const data = projects.project ? articles.popularInProject : articles.popular;
  return (
    data?.length ? (
      <Popular>
        <Title>Частые вопросы</Title>
        <Questions>
          {data.map(article => (
            <a
              href={
                RouteNames.USER_ARTICLE_PAGE.replace(
                  RouteParameters.PROJECT_SLUG,
                  article.projectSlug || ''
                ).replace(
                  RouteParameters.SECTION_SLUG,
                  article.sectionSlug || ''
                ).replace(
                  RouteParameters.ARTICLE_ID,
                  article.id.toString()
                )
              }
              key={article.id}
            >
              <Question>{article.title}</Question>
            </a>
          ))}
        </Questions>
      </Popular>
    ) : null
  );
};

export default PopularComponent;
